
export interface Message {
    role: string;
    content: string;
}

export interface Config {
    welcome: string;
    questions: string[];
    prompt: {
        model: string;
        messages: Message[];
        max_tokens: number;
    };
    announcement: string | null;
}

const API = process.env.REACT_APP_API + "/api/salieri/config";

export async function pull_config(): Promise<Config> {
    const response = await fetch(API, {
        method: "GET",
        credentials: "same-origin"
    });
    const json = await response.json();
    if (json.error) {
        throw new Error(json.error);
    }
    return json;
}

export async function push_config(config: Config): Promise<void> {
    console.log(config);
    const response = await fetch(API, {
        method: "POST",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(config)
    });
    if (!response.ok) {
        throw new Error("Failed to push config");
    }
    const json = await response.json();
    if (json.error) {
        throw new Error(json.error);
    }
}