import { Alert, Box, Button, Collapse, Container, Grid, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Message, pull_config, push_config } from "./service";
import { blue, grey } from "@mui/material/colors";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

const SystemHeader = (props: {}) => {
  return <Grid container spacing={2}>
    <Grid item xs={6}>
      {/* Show a text logo: Salieri System, with foot text aligned to the right: by Tom Shen */}
      <Typography variant="h4" sx={{
        fontWeight: 700,
        fontFamily: "Source Sans Pro",
        color: blue[700],
        textAlign: "right",
        fontSize: {
          xs: 16,
          sm: 24,
          md: 32,
        }
      }}>
        Salieri System
      </Typography>
      <Typography variant="body2" sx={{
        fontWeight: 400,
        fontFamily: "Source Sans Pro",
        color: grey[700],
        textAlign: "right",
        fontSize: {
          xs: 12,
          sm: 16,
        }
      }}>
        by Tom Shen
      </Typography>
    </Grid>

    <Grid item xs={6} sx={{
    }}>
      <Typography variant="h4" sx={{
        fontWeight: 700,
        fontFamily: "Source Sans Pro",
        color: grey[900],
        textAlign: "left",
        fontSize: {
          xs: 24,
          md: 32,
        },
        paddingTop: {
          xs: 0,
          md: 1,
        }

      }}>
        Configuration
      </Typography>
    </Grid>
  </Grid>
}

// Section Container that can be hidden using a toggle button. Can have multiple children.
const Section = (props: { title: string, children: React.ReactNode }) => {
  const [hidden, setHidden] = useState(false);

  return <>

    <Typography variant="h6" sx={{
      fontWeight: 700,
      fontFamily: "Source Sans Pro",
      color: grey[900],
      textAlign: "left",
      fontSize: {
        xs: 16,
        md: 24,
      },
      marginTop: 2,
    }}>
      <IconButton
        size="small"
        onClick={() => {
          setHidden(!hidden);
        }}
      >
        {hidden ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </IconButton> {props.title}
    </Typography>


    <Collapse in={!hidden}>
      {props.children}
    </Collapse>
  </>



}

const SyncGadget = (props: {
  synced: boolean,
  ready: boolean,
  error: Error | null,
  requestSync: () => void,
}) => {
  let status = props.synced ? "All Saved!" : "Unsaved Changes";
  if (!props.ready) {
    status = "Still Loading...";
  }
  if (props.error) {
    status = props.error.message;
  }
  let severity: "success" | "warning" | "info" | "error" = props.synced ? "success" : "warning";
  if (!props.ready) {
    severity = "info";
  }
  if (props.error) {
    severity = "error";
  }
  return <Box sx={{
    marginTop: 1,
  }}>
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item sx={{
        flexGrow: 1,
      }}>
        {<Alert severity={severity} >
          {status}
        </Alert>}

      </Grid>
      <Grid item>
        {!props.synced && (
          <Button variant="contained" onClick={() => { props.requestSync(); }} sx={{
            marginLeft: 1,
          }} disabled={!props.ready}>
            Sync
          </Button>
        )}
      </Grid>
    </Grid>
  </Box>

}

function App() {
  // data
  const [dataWelcome, setDataWelcome] = useState("");
  const [dataQuestions, setDataQuestions] = useState<string[]>([]);
  const [dataModel, setDataModel] = useState<string>("");
  const [dataMessages, setDataMessages] = useState<Message[]>([]);
  const [dataMaxTokens, setDataMaxTokens] = useState<number>(0);
  const [dataAnnouncement, setDataAnnouncement] = useState<string>("");

  // states

  const [ready, setReady] = useState(false);
  const [synced, setSynced] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const pullData = useCallback(async () => {
    try {
      setReady(false);
      const config = await pull_config();
      setDataWelcome(config.welcome);
      setDataQuestions(config.questions);
      setDataModel(config.prompt.model);
      setDataMessages(config.prompt.messages);
      setDataMaxTokens(config.prompt.max_tokens);
      setDataAnnouncement(config.announcement || "");
      setSynced(true);
      setReady(true);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }

  }, []);

  const pushData = useCallback(async () => {
    setReady(false);
    const config = {
      welcome: dataWelcome,
      questions: dataQuestions,
      announcement: dataAnnouncement.length == 0 ? null : dataAnnouncement,
      prompt: {
        model: dataModel,
        messages: dataMessages,
        max_tokens: dataMaxTokens,
      },
    }
    try {
      await push_config(config);
      setSynced(true);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setReady(true);

  }, [dataWelcome, dataQuestions, dataModel, dataMessages, dataMaxTokens, dataAnnouncement]);



  useEffect(() => {
    (async () => {
      await pullData();
    })();
  }, [pullData]);

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (!synced && ready) {
        e.preventDefault();
        e.returnValue = "";
      }
    }
  }, [synced, ready]);

  // if any data is changed, set synced to false
  const onDataChange = useCallback(() => {
    setSynced(false);
  }, []);

  return (

    <Container maxWidth="md">
      <SystemHeader />
      {/* Sync Status */}
      <SyncGadget synced={synced} error={error} ready={ready} requestSync={() => { pushData() }} />
      <Section title="Welcome Message">
        <TextField label="Welcome Message" variant="outlined" fullWidth multiline rows={4} sx={{
          marginTop: 2,
        }}
          value={dataWelcome}
          onChange={(e) => {
            setDataWelcome(e.target.value);
            onDataChange();
          }}
          disabled={!ready}
        />
        <TextField label="Announcement" variant="outlined" fullWidth multiline rows={4} sx={{
          marginTop: 2,
        }}
          value={dataAnnouncement}
          onChange={(e) => {
            setDataAnnouncement(e.target.value);
            onDataChange();
          }}
          disabled={!ready}
        />
      </Section>

      <Section title="Suggested Questions">
        {dataQuestions.map((question, index) => {
          return <TextField hiddenLabel key={index} size="small" variant="standard" required={true} fullWidth sx={{
            marginTop: 1,
          }}
            id={`question-${index}`}
            value={question}
            onChange={(e) => {
              const newValue = e.target.value;
              setDataQuestions((prev) => prev.map((q, i) => i === index ? newValue : q));
              onDataChange();
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                // add a new text field after this one
                setDataQuestions((prev) => [...prev.slice(0, index + 1), "", ...prev.slice(index + 1)]);
                onDataChange();
                // focus on the new text field
                setTimeout(() => {
                  const newTextField = document.getElementById(`question-${index + 1}`) as HTMLInputElement;
                  newTextField.focus();
                }, 0);

                e.preventDefault();
              } else if (e.key === "Backspace" && question === "") {
                // remove this text field
                setDataQuestions((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
                onDataChange();
                // focus on the previous text field
                const idx = Math.max(0, index - 1);
                const newTextField = document.getElementById(`question-${idx}`) as HTMLInputElement;
                newTextField.focus();
                e.preventDefault();
              } else if (e.key === "ArrowUp") {
                // focus on the previous text field
                const idx = Math.max(0, index - 1);
                const newTextField = document.getElementById(`question-${idx}`) as HTMLInputElement;
                newTextField.focus();
                e.preventDefault();
              } else if (e.key === "ArrowDown") {
                // focus on the next text field
                const idx = Math.min(dataQuestions.length - 1, index + 1);
                const newTextField = document.getElementById(`question-${idx}`) as HTMLInputElement;
                newTextField.focus();
                e.preventDefault();
              }
            }}
            disabled={!ready}
          />
        })}
      </Section>
      <Section title="Prompt">
        <TextField label="Model" variant="outlined" fullWidth sx={{
          marginTop: 2,
        }} value={dataModel} onChange={(e) => {
          setDataModel(e.target.value);
          onDataChange();
        }} disabled={!ready} />
        <TextField label="Max Tokens" variant="outlined" fullWidth sx={{
          marginTop: 2,
        }} value={dataMaxTokens} onChange={(e) => {
          let value = parseInt(e.target.value);
          if (isNaN(value) || value < 0) {
            value = 0;
          }
          value = Math.min(value, 4096);
          setDataMaxTokens(value);
          onDataChange();
        }} disabled={!ready} />
        {
          dataMessages.map((message, index) =>
            <Box key={index} sx={{
              marginTop: 2,
            }}>
              <Box sx={{
                display: "flex",
                justifyContent: "left",
              }}>
                <Select value={message.role} onChange={(e) => {
                  const newValue = e.target.value as string;
                  setDataMessages((prev) => prev.map((m, i) => i === index ? { ...m, role: newValue } : m));
                  onDataChange();
                }} disabled={!ready}>
                  {index === 0 && <MenuItem value="system">System</MenuItem>}
                  {index > 0 && <MenuItem value="assistant">Assistant</MenuItem>}
                  {index > 0 && <MenuItem value="user">User</MenuItem>}

                </Select>
                {index > 0 &&
                  <>
                    <IconButton onClick={() => {
                      setDataMessages((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
                      onDataChange();
                    }}>
                      <DeleteIcon />
                    </IconButton>
                    <IconButton onClick={() => {
                      setDataMessages((prev) => [...prev.slice(0, index), { role: "assistant", content: "" }, ...prev.slice(index)]);
                      onDataChange();
                    }}>
                      <VerticalAlignTopIcon />
                    </IconButton>

                  </>
                }
                <IconButton onClick={() => {
                  setDataMessages((prev) => [...prev.slice(0, index + 1), { role: "assistant", content: "" }, ...prev.slice(index + 1)]);
                  onDataChange();
                }}>
                  <VerticalAlignBottomIcon />
                </IconButton>


              </Box>

              <TextField label="Message" variant="outlined" fullWidth multiline minRows={1} sx={{
                marginTop: 2,
              }}
                value={message.content}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setDataMessages((prev) => prev.map((m, i) => i === index ? { ...m, content: newValue } : m));
                  onDataChange();
                }} disabled={!ready}
              />
            </Box>)
        }
      </Section>
      <SyncGadget synced={synced} error={error} ready={ready} requestSync={() => { pushData() }} />
    </Container>

  );
}

export default App;
